export function addEventListener(elements, events, handler) {
    _addOrRemoveListener('add', elements, events, handler);
}

export function removeEventListener(elements, events, handler) {
    _addOrRemoveListener('remove', elements, events, handler);
}

export function _addOrRemoveListener(action, elements, events, handler) {
    const eventsArray = events.split(' ');

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        for (let j = 0; j < eventsArray.length; j++) {
            const event = eventsArray[j];

            if (action === 'add') {
                element.addEventListener(event, handler);
            } else {
                element.removeEventListener(event, handler);
            }
        }
    }
}
