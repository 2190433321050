import '../../../Shared/js/polyfills';

import { WhistleOutContextWidget as WhistleOutContext } from './common';
import { widgetInit } from './widget';

(async () => {
    if (window.WhistleOut.isInitRequested) {
        return;
    }

    await import(/* webpackMode: 'eager' */ '../vendor');

    // Some properties of window.WhistleOut can be initialised
    // by inline scripts before this main initialisation,
    // so we need to merge it
    window.WhistleOut = Object.assign(new WhistleOutContext(), window.WhistleOut || {});

    await window.WhistleOut.init();
    widgetInit();
})();
