import { pushDataLayerItemWidget } from '../../../Shared/js/utils/datalayer';
import * as EventHelper from './utils/EventHelper';

export function getWidget() {
    return wo$('#widget');
}

export function widgetInit() {
    wo$(function () {
        const widget = getWidget();
        if (!widget.length) return;

        const controller = {
            resizeTaskId: null,
            resizeTaskAmpId: null,
            init: function () {
                const amp = widget.data('amp').toLowerCase() === 'true';
                const url = widget.data('url');

                const startTime = Date.now();
                pushDataLayerItemWidget({
                    event: 'widget_load_begin',
                    widget_placement: 'iframe'
                });

                const el = widget[0];

                if (amp) {
                    EventHelper.addEventListener([window, el, el.parentElement], 'resize', this.resizeAmp.bind(this));
                    EventHelper.addEventListener([window], 'load', this.resizeAmp.bind(this));
                } else {
                    EventHelper.addEventListener([window, el, el.parentElement], 'resize', this.resize.bind(this));
                    EventHelper.addEventListener([window], 'load', this.resize.bind(this));
                }

                wo$.ajax({
                    context: this,
                    type: 'GET',
                    url: url,
                    contentType: 'text/html'
                })
                    .done(function (data) {
                        widget.find('#widget-content').html(data);

                        WhistleOut.applyPopover();
                        WhistleOut.showTracking();

                        if (WhistleOut.initWidget) {
                            WhistleOut.initWidget();
                        }
                    })
                    .fail(function (jqXHR, textStatus, error) {
                        widget.find('#widget-content').html(widget.find('#widget-error').html());

                        WhistleOut.Rollbar.error(
                            `ajax error in Widget.js requesting ${url}, status: ${textStatus}, error: ${error}, responseText: ${jqXHR.responseText}`
                        );

                        pushDataLayerItemWidget({
                            event: 'widget_error',
                            widget_placement: 'iframe',
                            error: error
                        });
                    })
                    .always(() => {
                        widget.find('#widget-error').remove();
                        widget.find('#widget-loading').remove();

                        const endTime = Date.now();
                        pushDataLayerItemWidget({
                            event: 'widget_load_end',
                            widget_placement: 'iframe',
                            duration: (endTime - startTime) / 1000
                        });

                        if (amp) {
                            this.resizeAmp();
                        } else {
                            this.resize();
                        }
                    });
            },

            resize: function () {
                cancelIdleCallback(this.resizeTaskId);
                this.resizeTaskId = requestIdleCallback(this._resize.bind(this), {
                    timeout: 300
                });
            },

            _resize: function () {
                window.parent.postMessage(
                    {
                        sentinel: 'whistleout-widget',
                        url: document.location.href,
                        height: widget.height()
                    },
                    '*'
                );
            },

            resizeAmp: function () {
                cancelIdleCallback(this.resizeTaskAmpId);
                this.resizeTaskAmpId = requestIdleCallback(this._resizeAmp.bind(this), {
                    timeout: 300
                });
            },

            _resizeAmp: function () {
                window.parent.postMessage(
                    {
                        sentinel: 'amp',
                        type: 'embed-size',
                        height: widget.height()
                    },
                    '*'
                );
            }
        };

        controller.init();
    });
}
