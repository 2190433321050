import * as ReferrerHelper from './common/utils/ReferrerHelper';

(function () {
    'use strict';

    function main() {

        var controller = {
            init: function () {
                var widget = wo$('[data-widget-type="broadband-search"]');
                if (!widget.length) return;

                widget.find('#change-usage').off('click').on('click',
                    function () {
                        WhistleOut.trackEvent('BroadbandSearch', 'ChangeUsage');
                    });
                widget.find('#view-full-results').off('click').on('click',
                    function () {
                        WhistleOut.trackEvent('BroadbandSearch', 'ViewFullResults');
                    });
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                widget.find('[data-transaction]').off('click').each(function () {
                });
                widget.find('[data-transaction]').off('click').each(function () {

                    // TODO: href logic to common

                    const $this = wo$(this);
                    const transaction = $this.data('transaction');
                    const supplier = $this.data('supplier');
                    if (transaction === 'GoToSite') {
                        const href = $this.attr('href');
                        $this.attr('href',
                            href +
                            (href.indexOf('?') === -1 ? '?' : '&') +
                            'r=' +
                            ReferrerHelper.getReferrerEncoded());
                    }

                    $this.on('click',
                        function () {
                            WhistleOut.trackEvent('BroadbandSearch', transaction, supplier);
                        });
                });
            }
        };

        WhistleOut.initWidget = controller.init;
        controller.init();
    };

    WhistleOut.initModule(import.meta.url, main);

}());