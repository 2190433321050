/* globals ga */

import type { WoDataLayerItem } from '../../../Shared/js/@types/WoDataLayer';
import { WhistleOutContext } from '../../../Shared/js/common/common';

export class WhistleOutContextWidget extends WhistleOutContext {
    constructor() {
        super();
        this.scope = 'widget';
    }

    pushGtmEvent(category: string, action: string, label: string, value: { nonInteraction: boolean }) {
        try {
            const analyticsClientSideData = this.getAnalyticsClientSideData();
            const data = analyticsClientSideData.data;

            this.pushDataLayerItem({
                event: `wo_${category}_${action}`.toLowerCase(),
                customCategory: category,
                customAction: action,
                customLabel: label,
                customValue: isNaN(value as never) ? undefined : +value,
                customCountry: data.country,
                customProductAreaId: data.productAreaId
            });
        } catch (err) {
            this.Rollbar.error(`WhistleOut.pushGtmEvent: category=${category}, action=${action}, label=${label}`, err);
        }
    }

    sendPageView(url: string) {
        const analyticsClientSideData = this.getAnalyticsClientSideData();
        const config = analyticsClientSideData.config;

        try {
            if (config.trackPageImpression) {
                if ((!url || url.length <= 0) && analyticsClientSideData.data)
                    url = analyticsClientSideData.data.trackingUrl;

                wo$.each(config.accounts, function (index, account) {
                    ga('create', account.accountNumber, { name: account.trackerId, useAmpClientId: true }, 'auto');
                    if (account.linkIdTracking) {
                        ga(account.trackerId + '.require', 'linkid');
                    }
                    ga(account.trackerId + '.set', 'anonymizeIp', true);
                    if (!url || url.length <= 0) {
                        ga(account.trackerId + '.send', 'pageview');
                    } else {
                        ga(account.trackerId + '.send', 'pageview', {
                            page: url
                        });
                    }
                });
            }
        } catch (err) {
            this.Rollbar.error(`WhistleOut.sendPageView url=${url}`, err);
        }
    }

    pushGtmWidgetPageView(url: string) {
        try {
            const analyticsClientSideData = this.getAnalyticsClientSideData();
            const customCountry = analyticsClientSideData.data.country;

            if (!url || url.length <= 0) {
                url = analyticsClientSideData.data.trackingUrl;
            }

            if (!url || url.length <= 0) url = `${location.pathname}${location.search}`;

            const dataLayerValue = <WoDataLayerItem>{
                event: 'widgetCustomPageView',
                customUrl: url,
                customCountry: customCountry
            };

            this.pushDataLayerItem(dataLayerValue);
        } catch (err) {
            this.Rollbar.error('WhistleOut.ushGtmPageView', err);
        }
    }

    trackPageView(url?: string) {
        try {
            this.sendPageView(url);
            this.pushGtmWidgetPageView(url);
        } catch (err) {
            this.Rollbar.error(`WhistleOut.trackPageView url=${url}`, err);
        }
    }

    encodeHtml(str: string) {
        const el = document.createElement('span');
        el.append(document.createTextNode(str));
        return el.innerHTML;
    }

    async init() {
        if (this.isInitRequested) {
            return;
        }

        await this.initBase();
    }
}
